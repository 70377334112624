
    
.projects{
    height: 100vh;
}

.pro-title{
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 400;
    color: yellow;
    margin-top: 8rem;
    margin-bottom: 2rem;

}

.project-decp{
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 400;
    width: 100vw;
    max-width: 75rem;
    margin-left: 5rem;
}

.project-card{
    display: flex;
    flex-direction: row;
    width: 100vw;
    max-width: 75rem;
    overflow: hidden;
    margin: 2rem;
    justify-content: center;
    

}

.avatarss{
    width:300px;
    height:300px
}
.flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    border: 1px solid #f1f1f1;
    perspective: 1000px;
    margin: 2rem;
     /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  /* Style the back side */
  .flip-card-back {
    background-color: yellow;
    color: black;
    transform: rotateY(180deg);
    padding: 1rem;
  }

.btn-click{
    background: white;
    margin: .2rem 0;
    padding: 0.8rem;
    border: none;
    border-radius: 1.5rem;
    font-size: 1rem;
    width: 8rem;
    font-weight:500;

}

.btn-click:hover {
    background-color: yellowgreen;
   /*  font-size: 1rem; */
}



 
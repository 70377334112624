#Intro{
    height: calc(100vh-4rem);
    width: 100vw;
    max-width: 75rem;
    overflow: hidden;
    margin: 0 auto;

}

.bg{
    position: absolute;
    z-index: -1;
    top: -4rem;
    right: 0;
    object-fit: cover;
}

.introContent{
    height: 100vh;
    width: 100vh;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 500;
    flex-direction: column;
    justify-content: center;
}

.hello{
    font-size: 1.7rem;
    font-weight: 100;


}

.name{
    color: yellow;
}
.shortintro{
    font-size: medium;
    font-weight: 200;
}

.btn{
    background: white;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 2rem;

}

.btnImg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;    
}
.bg{
    object-fit: cover;
    height: 30rem;
    border-radius: 2rem;
    margin: 10rem;
    
}
@media screen and (max-width:840px){
    .bg{
        right: -10vw;
    }
    .introContent {
        font-size: 10vw;
    }
    .hello{
        font-size: 4.5vw;
    }
    
}

@media screen and (max-width:480px){
    .bg{
        right: -20vw;
  
    }
    
}